
import { computed, defineComponent, onMounted, ref } from "vue";
import RouterLinkButton from "@/components/RouterLinkButton.vue";
import BackButton from "@/components/BackButton.vue";
import { RouteNames } from "@/router";
import ColorPicker from "@/components/ColorPicker.vue";
import ButterflyCanvas, { ChangeEvent } from "@/components/ButterflyCanvas.vue";
import { useStore } from "vuex";
import {
  getButterflyDataUrl,
  getButterflyDesignType,
  key,
  setButterflyDataUrl,
} from "@/store";
import { getDesignTypeSvgSrc } from "@/models/ButterflyDesignType";
import { RouteLocationRaw, useRouter } from "vue-router";
import { useLogger } from "@/plugins/logger/VueLogger";
import { Tap } from "@/components/Tap";

export default defineComponent({
  name: "ColorButterfly",
  components: {
    RouterLinkButton,
    BackButton,
    ColorPicker,
    ButterflyCanvas,
  },
  directives: {
    Tap,
  },
  setup() {
    const log = useLogger("ColorButterfly");
    const router = useRouter();
    const store = useStore(key);

    onMounted(() => {
      const designType = getButterflyDesignType(store);
      if (!designType) {
        log.warn("DesignType not set.");
        router.replace({ name: RouteNames.Home });
      }
    });

    const selectedColor = ref<string | null>(null);

    return {
      prevRoute: { name: RouteNames.PickButterfly } as RouteLocationRaw,
      nextRoute: { name: RouteNames.NameButterfly } as RouteLocationRaw,
      canGoNext: computed(() => !!getButterflyDataUrl(store)),

      gradients: [
        ["#FF67AD", "#834396"],
        ["#FFF500", "#FF7F00"],
        ["#00FFEB", "#0049AD"],
        ["#A1FF5D", "#1E6C10"],
        ["#F3D9FF", "#420083"],
      ],
      colors: [
        "#F11456",
        "#FFAA2E",
        "#46CCCB",
        "#0076FF",
        "#9E30E3",
        "#E6B1CC",
        "#FFCD9A",
        "#ABD96D",
        "#92B6E0",
        "#AC98C1",
        "#FCFEFF",
        "#BFBFBF",
        "#CBB187",
        "#885E2D",
        "#000000",
      ],
      butterflySrc: computed(() => {
        const designType = getButterflyDesignType(store);
        return designType ? getDesignTypeSvgSrc(designType) : null;
      }),

      selectedColor,
      onSelectColor: (color: string) => {
        log.trace("onSelectColor", { color });
        selectedColor.value = color;
      },
      onChange: ($event: ChangeEvent) => {
        log.trace("onChange");
        setButterflyDataUrl(store, $event.toDataUrl());
      },
    };
  },
});
