import { MathUtils } from "three";
import { Behavior } from "./Behavior";
import { Butterfly } from "../objects/Butterfly";
import gsap from "gsap";

/**
 * Animates the wings on a Butterfly object.
 */
export class ButterflyWingsBehavior implements Behavior {
  /**
   * A value between -1 and 1 representing the position of the wings.
   */
  public wingPosition: number;

  /**
   * @constructor
   *
   * @param butterfly - The butterfly instance to update.
   */
  constructor(private readonly butterfly: Butterfly) {
    this.wingPosition = -1;
    this.flap();
  }

  public update(): void {
    if (!this.butterfly.leftWing || !this.butterfly.rightWing) {
      return;
    }

    const wingFlapMin = 15;
    const wingFlapMax = 75;

    const angle = MathUtils.mapLinear(
      this.wingPosition,
      -1,
      1,
      wingFlapMin,
      wingFlapMin - wingFlapMax
    );
    this.butterfly.leftWing.mesh.rotation.y = MathUtils.degToRad(angle);
    this.butterfly.rightWing.mesh.rotation.y = MathUtils.degToRad(-angle);
  }

  private flap(): void {
    const wingInterval = 0.75;
    const rand = Math.random() * (wingInterval * 0.75);
    const speed = wingInterval * 0.5 + rand;
    const rate = Math.random() * rand;

    const timeline = gsap.timeline({
      onComplete: () => {
        if (!this.butterfly.isDisposed) {
          setTimeout(() => this.flap(), rate * 1000);
        }
      },
    });
    timeline.to(
      this,
      { duration: speed / 2, ease: `sine.in`, wingPosition: 1 },
      0
    );
    timeline.to(
      this,
      { duration: speed / 2, ease: `sine.out`, wingPosition: -1 },
      speed / 2
    );
  }
}
