
import { defineComponent, PropType, ref, watch } from "vue";

/**
 * Allows the user to select a color from a list of colors or gradients.
 */
export default defineComponent({
  name: "ColorPicker",
  props: {
    /**
     * An array of gradients the user can select from. Each gradient should be
     * passed in as an array of colors.
     * For example: [['#000', '#FFF'], ['#FFF', '#000']]
     */
    gradients: {
      type: Array as PropType<Array<Array<string>>>,
      required: true,
    },
    /**
     * An array of colors the user can select from.
     */
    colors: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  emits: [
    /**
     * Emitted when the user has selected a color or gradient.
     */
    "selectColor",
  ],
  setup(_, { emit }) {
    const selectedColor = ref<string | Array<string> | null>(null);
    watch(selectedColor, (value) => emit("selectColor", { color: value }));

    return {
      isSelected: (color: string | Array<string>) => {
        return selectedColor.value?.toString() === color.toString();
      },
      onClick: (color: string | Array<string>) => {
        selectedColor.value = color;
      },
    };
  },
});
