import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteLocationNormalizedLoaded,
  RouteRecordRaw,
} from "vue-router";
import AppRouterView from "../components/AppRouterView.vue";
import ColorButterfly from "../views/ColorButterfly.vue";
import Home from "../views/Home.vue";
import NameButterfly from "../views/NameButterfly.vue";
import PickButterfly from "../views/PickButterfly.vue";
import ReleaseButterfly from "../views/ReleaseButterfly.vue";

export enum RouteNames {
  Home = "Home",
  PickButterfly = "PickButterfly",
  ColorButterfly = "ColorButterfly",
  NameButterfly = "NameButterfly",
  ReleaseButterfly = "ReleaseButterfly",
  Settings = "Settings",
}

const catchAllRoute = { path: "/:pathMatch(.*)*", redirect: "/" };
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteNames.Home,
    component: Home,
    meta: {
      transition: "fade",
    },
  },
  {
    path: "/butterflies",
    component: AppRouterView,
    props: {
      keepAlive: true,
    },
    children: [
      {
        path: "pick",
        name: RouteNames.PickButterfly,
        component: PickButterfly,
        meta: {
          transition: butterflyTransition,
          order: 1,
        },
      },
      {
        path: "color",
        name: RouteNames.ColorButterfly,
        component: ColorButterfly,
        meta: {
          transition: butterflyTransition,
          order: 2,
        },
      },
      {
        path: "name",
        name: RouteNames.NameButterfly,
        component: NameButterfly,
        meta: {
          transition: butterflyTransition,
          order: 3,
        },
      },
      {
        path: "release",
        name: RouteNames.ReleaseButterfly,
        component: ReleaseButterfly,
        meta: {
          transition: butterflyTransition,
          order: 4,
        },
      },
    ],
  },
];

const router = process.env.IS_ELECTRON
  ? createRouter({
      history: createWebHashHistory(),
      routes: routes.concat([
        {
          path: "/settings",
          name: RouteNames.Settings,
          component: () => import("../views/Settings.vue"),
        },
        catchAllRoute,
      ]),
    })
  : createRouter({
      history: createWebHistory(process.env.IS_ELECTRON),
      routes: routes.concat([catchAllRoute]),
    });

export default router;

function butterflyTransition(
  to: RouteLocationNormalizedLoaded,
  from: RouteLocationNormalizedLoaded
): string {
  if (!to.meta || typeof to.meta.order !== "number") {
    return "slide-left";
  }
  if (!from.meta || typeof from.meta.order !== "number") {
    return "slide-right";
  }
  return to.meta.order >= from.meta.order ? "slide-right" : "slide-left";
}
