
import { RouteNames } from "@/router";
import { getButterflyDesignType, key, setButterflyDesignType } from "@/store";
import { computed, defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";
import { useStore } from "vuex";
import RouterLinkButton from "@/components/RouterLinkButton.vue";
import BackButton from "@/components/BackButton.vue";
import {
  ButterflyDesignType,
  getDesignTypeLabel,
  getDesignTypeSvgSrc,
} from "@/models/ButterflyDesignType";
import { useLogger } from "@/plugins/logger/VueLogger";
import { Tap } from "@/components/Tap";

export default defineComponent({
  name: "PickButterfly",
  components: {
    RouterLinkButton,
    BackButton,
  },
  directives: {
    Tap,
  },
  setup() {
    const log = useLogger("PickButterfly");
    const store = useStore(key);

    return {
      prevRoute: { name: RouteNames.Home } as RouteLocationRaw,
      nextRoute: { name: RouteNames.ColorButterfly } as RouteLocationRaw,
      canGoNext: computed(() => !!getButterflyDesignType(store)),

      butterflies: Object.values(ButterflyDesignType).reverse(),
      getDesignTypeLabel,
      getDesignTypeSvgSrc,

      selectedButterfly: computed(() => getButterflyDesignType(store)),
      onClickButterfly: (designType: ButterflyDesignType) => {
        log.trace("onClickButterfly", { designType });
        setButterflyDesignType(store, designType);
      },
    };
  },
});
