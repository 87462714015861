import { range } from "lodash";
import { Object3D } from "three";
import { Behavior } from "./Behavior";

/**
 * Offsets the position of an object each update.
 */
export class WobbleBehavior implements Behavior {
  private readonly noise: Noise1D;

  private time: number;
  private flap: number;

  /**
   * @constructor
   *
   * @param object - The object to update.
   * @param speed - The speed the offset changes. Evaluated on each update.
   * @param amplitude - The amplitude of the offset. Evaluated on each update.
   */
  constructor(
    private readonly object: Object3D,
    private readonly speed: () => number,
    private readonly amplitude: () => number
  ) {
    this.noise = new Noise1D();
    this.time = 0;
    this.flap = 0;
  }

  public update(deltaTime: number): void {
    const wobbleSpeed = 0.005;
    const wobbleFlap = 0.1;
    const rate = 0.12;

    this.time += deltaTime;
    this.flap += (1 + this.speed()) * wobbleFlap;

    const offset = this.time * wobbleSpeed + this.flap;
    const nx = this.noise.getVal(offset, this.amplitude(), rate);
    const ny = this.noise.getVal(offset + 1000, this.amplitude(), rate);
    const nz = this.noise.getVal(offset + 2000, this.amplitude(), rate);

    this.object.position.set(nx, ny, nz);
  }
}

class Noise1D {
  private static readonly MAX_VERTICES = 256;

  private readonly _r: Array<number>;

  constructor() {
    this._r = range(Noise1D.MAX_VERTICES).map(() => Math.random());
  }

  public getVal(x: number, amplitude: number, rate: number): number {
    const scaledX = x * rate;
    const xFloor = Math.floor(scaledX);
    const t = scaledX - xFloor;
    const tRemapSmoothstep = t * t * (3 - 2 * t);

    const xMin = xFloor % (Noise1D.MAX_VERTICES - 1);
    const xMax = (xMin + 1) % (Noise1D.MAX_VERTICES - 1);

    const val =
      this._r[xMin] * (1 - tRemapSmoothstep) + this._r[xMax] * tRemapSmoothstep;

    return val * amplitude * 2 - amplitude;
  }
}
