import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b81ff02"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_wave = _resolveDirective("wave")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: "router-link-button",
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 8, _hoisted_1)), [
    [_directive_wave]
  ])
}