import ResizeObserver from "resize-observer-polyfill";
import { watch, Ref, onUnmounted, onMounted } from "vue";

export function useResizeObserver(
  target: Ref<Element>,
  callback: ResizeObserverCallback
): void;
export function useResizeObserver(
  target: Ref<Element | null>,
  callback: ResizeObserverCallback
): void;
export function useResizeObserver(
  target: Ref<Element | undefined>,
  callback: ResizeObserverCallback
): void;

/**
 * Based on the useResizeObserver method from @vueuse/core, but uses the resize-observer-polyfill package
 * to ensure compatibility with older iOS devices.
 *
 * @param target - The target element. Whenever this element changes in size, the callback will be invoked.
 * @param callback - The callback to invoke when the element changes it's size.
 */
export function useResizeObserver(
  target: Ref<Element | null | undefined>,
  callback: ResizeObserverCallback
): void {
  const resizeObserver = new ResizeObserver(callback);
  onMounted(() => {
    if (target.value) {
      resizeObserver.observe(target.value);
    }
  });
  watch(target, (value, prevValue) => {
    if (prevValue) {
      resizeObserver.unobserve(prevValue);
    }
    if (value) {
      resizeObserver.observe(value);
    }
  });
  onUnmounted(() => resizeObserver.disconnect());
}
