import { delay, first } from "lodash";
import {
  Camera,
  MathUtils,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
import { ButterflyWingsBehavior } from "./behaviors/ButterflyWingsBehavior";
import { MoveBehavior } from "./behaviors/MoveBehavior";
import { WobbleBehavior } from "./behaviors/WobbleBehavior";
import { Butterfly } from "./objects/Butterfly";

/**
 * Scene that renders a butterfly flying away.
 */
export class ButterflyScene {
  private readonly scene: Scene;
  private readonly camera: PerspectiveCamera;
  private readonly renderer: WebGLRenderer;

  private readonly butterfly: Butterfly;

  private animationFrameHandle?: ReturnType<typeof requestAnimationFrame>;

  /**
   * @constructor
   *
   * @param canvas - The canvas element to render to.
   * @param width - The desired width of the canvas.
   * @param height - The desired height of the canvas.
   * @param butterflySrc - The url to the butterfly to render in the scene.
   */
  constructor(
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
    butterflySrc: string
  ) {
    this.render = this.render.bind(this);
    this.play = this.play.bind(this);
    this.dispose = this.dispose.bind(this);
    this.renderFrame = this.renderFrame.bind(this);

    this.scene = new Scene();

    this.camera = new PerspectiveCamera(45, width / height, 0.1, 1000);
    this.camera.position.set(0, 2.75, 0);
    this.camera.lookAt(0, 0, 0);
    this.camera.rotateZ(MathUtils.degToRad(180));

    this.renderer = new WebGLRenderer({
      canvas: canvas,
      powerPreference: "high-performance",
      precision: "lowp",
      alpha: true,
      antialias: false,
      stencil: true,
      depth: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);

    this.butterfly = new Butterfly(butterflySrc, new Vector3(0, 0, 0.17));
    this.scene.add(this.butterfly.object);
  }

  /**
   * Starts the render loop, which will render the butterfly onto the canvas element.
   * No animations will play until play() is called.
   */
  public render(): void {
    this.renderFrame(0, 0);
  }

  /**
   * Plays an animation of the butterfly flying away.
   */
  public async play(): Promise<void> {
    const butterflyWings = new ButterflyWingsBehavior(this.butterfly);

    const moveSpeed = () =>
      MathUtils.mapLinear(butterflyWings.wingPosition, -1, 1, 0, 1);
    const movePath: Array<[Vector3, number]> = [[new Vector3(-1, 0, 2), 0.7]];
    const move = new MoveBehavior(
      this.butterfly.object,
      movePath,
      2000,
      moveSpeed
    );

    const wobbleSpeed = () => butterflyWings.wingPosition;
    const amplitude = () => Math.min(move.progress * 10, 1) * 0.13;
    const wobble = new WobbleBehavior(
      this.butterfly.wings,
      wobbleSpeed,
      amplitude
    );

    return new Promise((resolve) => {
      this.butterfly.addBehavior(butterflyWings);
      delay(() => this.butterfly.addBehavior(move, wobble), 1500);
      delay(resolve, 5000);
    });
  }

  /**
   * Resets the position of the butterfly and stops any active animations.
   *
   * This can be used to replay the scene during testing.
   */
  public reset(): void {
    this.butterfly.object.position.set(0, 0, 0.17);
    this.butterfly.object.rotation.set(MathUtils.degToRad(90), 0, 0);
    this.butterfly.clearBehaviors();
  }

  /**
   * Resizes the scene and underlying canvas.
   *
   * @param width - The desired width of the canvas.
   * @param height - The desired height of the canvas.
   */
  public resize(width: number, height: number): void {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  /**
   * Disposes of underlying resources.
   */
  public dispose(): void {
    if (this.animationFrameHandle) {
      cancelAnimationFrame(this.animationFrameHandle);
    }

    this.butterfly.dispose();
    this.renderer.dispose();
  }

  private renderFrame(time: number, prevTime: number): void {
    this.animationFrameHandle = requestAnimationFrame((nextTime) =>
      this.renderFrame(nextTime, time)
    );

    this.butterfly.update(time - prevTime);
    this.renderer.render(this.scene, this.camera);
  }
}
