import { createLogger, Logger as _Logger } from "winston";
import TransportStream from "winston-transport";
import { Logger } from "./Logger";
import { LogLevel } from "./LogLevel";

/**
 * An implementation of Logger that leverages winston to write to a file and the console.
 */
export class WinstonLogger implements Logger {
  private readonly log: _Logger;
  private readonly defaultContext: string;

  constructor(
    level: LogLevel,
    transports: Array<TransportStream>,
    context = "Unknown"
  ) {
    this.log = createLogger({ level: level, transports: transports });
    this.defaultContext = context;
  }

  public setContext(context: string): Logger {
    return new WinstonLogger(
      this.log.level as LogLevel,
      this.log.transports,
      context
    );
  }

  public error(
    message: string,
    error: any,
    values: Record<string, any> = {},
    context?: string
  ): void {
    this.log.error({
      ...values,
      context: context || this.defaultContext,
      message: message,
      errorMessage: error.toString(),
      error: error,
    });
  }

  public warn(
    message: string,
    error?: any,
    values: Record<string, any> = {},
    context?: string
  ): void {
    this.log.warn({
      ...values,
      context: context || this.defaultContext,
      message: message,
      errorMessage: error ? error.toString() : undefined,
      error: error,
    });
  }

  public info(
    message: string,
    values: Record<string, any> = {},
    context?: string
  ): void {
    this.log.info({
      ...values,
      context: context || this.defaultContext,
      message: message,
    });
  }

  public debug(
    message: string,
    values: Record<string, any> = {},
    context?: string
  ): void {
    this.log.debug({
      ...values,
      context: context || this.defaultContext,
      message: message,
    });
  }

  public trace(
    method: string,
    values?: Record<string, any>,
    component?: string
  ): void {
    this.log.debug({
      ...(values || {}),
      context: component || this.defaultContext,
      message: method,
    });
  }
}
