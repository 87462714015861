import {
  BufferGeometry,
  DoubleSide,
  LinearFilter,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Texture,
  Vector2,
} from "three";
import { Disposable } from "./Disposable";

export class ButterflyWing implements Disposable {
  public readonly mesh: Mesh;
  public isDisposed: boolean;

  private readonly texture: Texture;
  private readonly geometry: BufferGeometry;
  private readonly material: MeshBasicMaterial;

  constructor(map: Texture, offset: number) {
    map.magFilter = LinearFilter;
    map.minFilter = LinearFilter;
    map.offset = new Vector2(offset / 2, 0);
    map.center = new Vector2(0.5, 0.5);
    map.repeat = new Vector2(0.5, 1);
    map.needsUpdate = true;

    this.texture = map;
    this.geometry = new PlaneBufferGeometry(0.5, 1).translate(offset / 2, 0, 0);
    this.material = new MeshBasicMaterial({ map, side: DoubleSide });
    this.mesh = new Mesh(this.geometry, this.material);
    this.isDisposed = false;
  }

  public dispose(): void {
    this.texture.dispose();
    this.geometry.dispose();
    this.material.dispose();
    this.isDisposed = true;
  }
}
