
import { useLogger } from "@/plugins/logger/VueLogger";
import { defineComponent, PropType, ref, watch } from "vue";
import InlineSvg from "vue-inline-svg";

/**
 * Represents a change to the underlying canvas element.
 */
export interface ChangeEvent {
  /**
   * Converts the underlying canvas element to a dataUrl.
   */
  toDataUrl: () => string;
}

/**
 * The butterfly canvas allows a user to customize a butterfly and provides
 * the ability to generate a base64 representation of the customized butterfly.
 */
export default defineComponent({
  name: "ButterflyCanvas",
  components: {
    InlineSvg,
  },
  props: {
    /**
     * The url to the svg the user can customize.
     */
    src: {
      type: String,
      required: true,
    },
    /**
     * An array of gradients the user can select from. Each gradient should be
     * passed in as an array of colors.
     * For example: [['#000', '#FFF'], ['#FFF', '#000']]
     */
    gradients: {
      type: Array as PropType<Array<Array<string>>>,
      required: true,
    },
    /**
     * An array of colors the user can select from.
     */
    colors: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    /**
     * The currently selected color or gradient. The next time the user taps on the
     * svg, a section is filled in with this color.
     */
    fillColor: {
      type: [Array, String],
      required: false,
    },
  },
  emits: [
    /**
     * Emitted when the underlying canvas is updated.
     */
    "change",
  ],
  setup(props, context) {
    const log = useLogger("ButterflyCanvas");
    const svg = ref<SVGElement | null>(null);
    const canvas = ref<HTMLCanvasElement | null>(null);

    const getGradientId = (gradient: Array<unknown>): string => {
      return `gradient_${gradient.join("_").replace(/#/g, "")}`;
    };

    watch([svg, () => props.gradients], ([svg, gradients]) => {
      if (!svg) return;
      if (!gradients) return;

      const svgNS = svg.namespaceURI;
      const defs =
        svg.querySelector("defs") ||
        svg.appendChild(document.createElementNS(svgNS, "defs"));
      for (const gradient of gradients) {
        const linearGradient = document.createElementNS(
          svgNS,
          "linearGradient"
        );
        linearGradient.setAttribute("id", getGradientId(gradient));
        linearGradient.setAttribute("x1", "0");
        linearGradient.setAttribute("x2", "0");
        linearGradient.setAttribute("y1", "0");
        linearGradient.setAttribute("y2", "1");

        for (let i = 0; i < gradient.length; i++) {
          const stop = document.createElementNS(svgNS, "stop", {});
          stop.setAttribute(
            "offset",
            `${((100 / (gradient.length - 1)) * i).toFixed()}%`
          );
          stop.setAttribute("stop-color", gradient[i]);
          linearGradient.appendChild(stop);
        }

        defs.appendChild(linearGradient);
      }
    });

    return {
      svg,
      canvas,
      onLoaded: ($event: SVGElement) => (svg.value = $event),
      onClick: ($event: MouseEvent) => {
        if (!svg.value) {
          log.warn("svg element not found");
          return;
        }
        if (!canvas.value) {
          log.warn("canvas element not found");
          return;
        }
        if (!props.fillColor) return;

        const target = $event.target as SVGElement;
        if (!target.classList.contains("cls-1")) {
          return;
        }

        if (Array.isArray(props.fillColor)) {
          target.style.fill = `url(#${getGradientId(props.fillColor)}`;
        } else {
          target.style.fill = props.fillColor;
        }

        drawSvgToCanvas(svg.value, canvas.value).then(() => {
          context.emit("change", {
            toDataUrl: () => !!canvas.value && canvas.value.toDataURL(),
          } as ChangeEvent);
        });
      },
    };
  },
});

function drawSvgToCanvas(
  svg: SVGElement,
  canvas: HTMLCanvasElement
): Promise<void> {
  const context = canvas.getContext("2d");
  if (!context) {
    return Promise.reject("Unable to get canvas context.");
  }

  return new Promise((resolve, reject) => {
    const blob = new Blob([svg.outerHTML], {
      type: "image/svg+xml",
    });
    const blobURL = URL.createObjectURL(blob);
    const img = new Image();
    img.addEventListener("load", () => {
      // Scale the image to fit within the canvas, while maintaining the aspect ratio.
      let dw = canvas.width,
        dh = canvas.height;
      if (img.height > img.width) {
        const aspectRatio = img.width / img.height;
        dw *= aspectRatio;
      } else if (img.height < img.width) {
        const aspectRatio = img.height / img.width;
        dh *= aspectRatio;
      }
      // Center the scaled image on the canvas
      const dx = (canvas.width - dw) / 2;
      const dy = (canvas.height - dh) / 2;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, dx, dy, dw, dh);
      URL.revokeObjectURL(blobURL);
      resolve();
    });
    img.addEventListener("error", (ev) => reject(ev.error));
    img.src = blobURL;
  });
}
