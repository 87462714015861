
import { defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

/**
 * Router view component with built-in transitions.
 *
 * The AppRouterView component looks that the current route's meta field
 * to determine which transition to use. The meta field is expected to
 * have a transition property, which can be one of three values:
 * - (to, from, routes) => string, A function that accepts the current 
     route, to, the previous route, from, and all configured routes. 
     The returned value is expected to be one of the supported transitions.
 * - string, A string value that is one of the supported transitions
 * - null/undefined, A falsey value means the configured default transition is used.
 * 
 * Supported transitions:
 * - slide-left, The next view slides in over the previous view from the left.
 * - slide-right, The next view slides in over the previous view from the right.
 * - fade, The next view fades in over the previous view.
 * - none, No transition is 
 */
export default defineComponent({
  name: "AppRouterView",
  props: {
    /**
     * Indicates if keep-alive should be used on the rendered route.
     */
    keepAlive: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * The name of the default transition to apply when a route does not define one.
     */
    defaultTransition: {
      type: String,
      required: false,
      default: "slide-left",
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const transition = ref(props.defaultTransition);
    watch(
      () => route.name,
      (toName, fromName) => {
        const routes = router.getRoutes();
        const to = routes.find((route) => route.name === toName);
        const from = routes.find((route) => route.name === fromName);

        if (!to || !to.meta || !to.meta.transition) {
          transition.value = props.defaultTransition;
        } else if (typeof to.meta.transition === "function") {
          transition.value = to.meta.transition(to, from, routes);
        } else if (typeof to.meta.transition === "string") {
          transition.value = to.meta.transition as string;
        } else {
          transition.value = props.defaultTransition;
        }
      }
    );

    return {
      transition,
    };
  },
});
