import { format, transports } from "winston";
import { LogLevel } from "./LogLevel";

/**
 * Options used to configure the Console transport.
 */
export interface ConsoleTransportOptions {
  /**
   * The log level to use for this transport only. If not provided,
   * the global log level is used instead.
   */
  logLevel?: LogLevel;
}

const defaultOptions: ConsoleTransportOptions = {};

/**
 * Creates a new instance of the ConsoleTransport to use with
 * a logger.
 *
 * @param options - Options used to configure the transport.
 * @returns - The transport to pass to the logger.
 */
export function ConsoleTransport(
  options: ConsoleTransportOptions = defaultOptions
) {
  return new transports.Console({
    level: options.logLevel,
    format: format.combine(
      format.colorize(),
      format((info) => {
        const { level, context, message, ..._info } = info;
        return {
          level: level,
          message: `${context} | ${message} | ${JSON.stringify(_info)}`,
        };
      })(),
      format.simple()
    ),
  });
}
