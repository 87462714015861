import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b2b176a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alert absolute bottom-0 left-0 right-0 flex flex-col"
}
const _hoisted_2 = { class: "flex-none m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "transition ease-out duration-500 transform",
    "enter-from-class": "translate-y-full",
    "enter-to-class": "translate-y-0",
    "leave-active-class": "transition ease-in duration-500 transform",
    "leave-from-class": "translate-y-0",
    "leave-to-class": "translate-y-full",
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}