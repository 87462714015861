
import { defineComponent, onMounted } from "vue";
import AppRouterView from "@/components/AppRouterView.vue";
import Idle from "@dimensional-innovations/vue-electron-idle";
import { useLogger } from "./plugins/logger/VueLogger";
import { useRouter } from "vue-router";
import { addErrorHandler, setBaseApiUrl } from "./api/Api";
import { RouteNames } from "./router";
import { useAppSettings } from "./plugins/app-settings/AppSettings";
import { setWebSocketUrl } from "./api/WebSocket";

export default defineComponent({
  name: "App",
  components: {
    AppRouterView,
    Idle,
  },
  setup() {
    const log = useLogger("App");
    const { webSocketUrl, baseApiUrl, idle } = useAppSettings();
    const router = useRouter();

    setWebSocketUrl(webSocketUrl);
    setBaseApiUrl(baseApiUrl);
    addErrorHandler((error) => log.error("http exception", error, {}, "Api"));

    router.afterEach((to, from) => {
      log.trace("afterNavigate", { to: to.fullPath, from: from.fullPath });
    });

    onMounted(() => log.trace("mounted"));

    if (process.env.IS_ELECTRON) {
      onMounted(() => {
        document.addEventListener("keydown", (e) => {
          if (e.key === "S") {
            router.push({ name: RouteNames.Settings });
          }
        });
      });
    }

    return {
      idle,
    };
  },
});
