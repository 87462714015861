
import { defineComponent, PropType } from "vue";
import { useRouter, RouteLocationRaw } from "vue-router";

/**
 * Button that navigates to the previous route.
 */
export default defineComponent({
  name: "BackButton",
});
