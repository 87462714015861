
import { RouteNames } from "@/router";
import {
  getButterflyDataUrl,
  getButterflyDesignType,
  getButterflyName,
  key,
  releaseButterfly,
} from "@/store";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import BackButton from "@/components/BackButton.vue";
import { useRouter } from "vue-router";
import { useLogger } from "@/plugins/logger/VueLogger";
import RouterLinkButton from "@/components/RouterLinkButton.vue";
import { ButterflyScene } from "@/scenes/ButterflyScene";
import { debounce, first, once } from "lodash";
import { useResizeObserver } from "@/plugins/resize-observer/useResizeObserver";
import { Tap } from "@/components/Tap";

export default defineComponent({
  name: "ReleaseButterfly",
  components: {
    RouterLinkButton,
    BackButton,
  },
  directives: {
    Tap,
  },
  setup() {
    const log = useLogger("ReleaseButterfly");
    const router = useRouter();
    const store = useStore(key);

    onMounted(() => {
      const designType = getButterflyDesignType(store);
      const dataUrl = getButterflyDataUrl(store);
      const name = getButterflyName(store);
      if (!designType || !dataUrl || !name) {
        log.warn("Butterfly not fully complete", null, {
          designType: designType || null,
          dataUrl: !!dataUrl,
          name: name || null,
        });
        router.replace({ name: RouteNames.Home });
      }
    });

    const canvasContainer = ref<HTMLDivElement>();
    const canvas = ref<HTMLCanvasElement>();
    const butterflySrc = computed(() => getButterflyDataUrl(store));
    const scene = ref<ButterflyScene>();
    watch([canvas, butterflySrc], ([canvas, butterflySrc]) => {
      if (!canvas || !butterflySrc) return;
      if (scene.value) {
        scene.value.dispose();
      }

      const _scene = new ButterflyScene(
        canvas,
        window.innerWidth,
        window.innerHeight,
        butterflySrc
      );
      _scene.render();

      scene.value = _scene;
    });
    useResizeObserver(
      canvasContainer,
      debounce((entries: Readonly<Array<ResizeObserverEntry>>) => {
        const target = first(entries)?.target as HTMLDivElement;
        if (!target || !target.offsetHeight || !target.offsetWidth) {
          return;
        }

        scene.value?.resize(target.offsetWidth, target.offsetHeight);
      }, 100)
    );
    onUnmounted(() => {
      scene.value?.dispose();
    });

    return {
      prevRoute: { name: RouteNames.NameButterfly },
      nextRoute: { name: RouteNames.Home },
      butterflyName: computed(() => getButterflyName(store)),
      butterflySrc,
      canvasContainer,
      canvas,
      onClick: once(async () => {
        log.trace("onClick");

        if (scene.value) {
          await scene.value.play();
        } else {
          log.warn("Scene was not created.");
        }

        // Attempt to create a butterfly in the cms. If this action fails, log the error,
        // but do not stop navigating back to the home screen.
        releaseButterfly(store).catch((error) =>
          log.error("Failed to create butterfly", error)
        );

        router.replace({ name: RouteNames.Home });
      }),
    };
  },
});
