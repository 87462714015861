import {
  Group,
  MathUtils,
  Object3D,
  Texture,
  TextureLoader,
  Vector3,
} from "three";
import { Behavior } from "../behaviors/Behavior";
import { BehaviorObject } from "./BehaviorObject";
import { ButterflyWing } from "./ButterflyWing";
import { Disposable } from "./Disposable";

export class Butterfly implements BehaviorObject, Disposable {
  public leftWing: ButterflyWing | null;
  public rightWing: ButterflyWing | null;
  public isDisposed: boolean;

  private readonly group: Group;
  private readonly behaviors: Array<Behavior>;

  constructor(butterflySrc: string, position: Vector3) {
    this.isDisposed = false;

    this.group = new Group();
    this.group.position.set(position.x, position.y, position.z);
    this.group.rotation.x = MathUtils.degToRad(90);

    this.behaviors = [];

    this.leftWing = null;
    this.rightWing = null;
    this.loadTexture(butterflySrc).then((map) => {
      const wingGroup = new Group();
      this.leftWing = new ButterflyWing(map.clone(), 0.5);
      this.rightWing = new ButterflyWing(map.clone(), -0.5);
      wingGroup.add(this.leftWing.mesh, this.rightWing.mesh);
      this.group.add(wingGroup);
    });
  }

  public get object(): Object3D {
    return this.group;
  }

  public get wings(): Object3D {
    return this.group.children[0];
  }

  public update(elapsedTime: number): void {
    for (let i = 0; i < this.behaviors.length; i++) {
      this.behaviors[i].update(elapsedTime);
    }
  }

  public dispose(): void {
    !!this.leftWing && this.leftWing.dispose();
    !!this.rightWing && this.rightWing.dispose();
    this.isDisposed = true;
  }

  public addBehavior(...behaviors: Array<Behavior>): void {
    for (const behavior of behaviors) {
      this.behaviors.push(behavior);
    }
  }

  public removeBehavior(behavior: Behavior): void {
    const index = this.behaviors.indexOf(behavior);
    if (index >= 0) {
      this.behaviors.splice(index, 1);
    }
  }

  public clearBehaviors(): void {
    this.behaviors.length = 0;
  }

  private loadTexture(src: string): Promise<Texture> {
    return new Promise((resolve, reject) => {
      new TextureLoader().load(src, resolve, undefined, reject);
    });
  }
}
