import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "butterfly-canvas relative" }
const _hoisted_2 = {
  ref: "canvas",
  class: "hidden",
  height: "512",
  width: "512"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512),
    _createVNode(_component_inline_svg, {
      src: _ctx.src,
      onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLoaded($event))),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick($event)))
    }, null, 8, ["src"])
  ]))
}