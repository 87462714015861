import { inject, InjectionKey, Plugin } from "vue";
import TransportStream from "winston-transport";
import { Logger } from "./Logger";
import { LogLevel } from "./LogLevel";
import { WinstonLogger } from "./WinstonLogger";

/**
 * Options used to customize the logger.
 */
export interface VueLoggerOptions {
  /**
   * The default logLevel for all transports.
   */
  level: LogLevel;

  /**
   * The transports used to write logs.
   */
  transports: Array<TransportStream>;
}

const key: InjectionKey<Logger> = Symbol();

/**
 * Registers a logger instance for use within Vue components.
 */
export const VueLogger: Plugin = {
  install: (app, options: VueLoggerOptions) => {
    const logger = new WinstonLogger(options.level, options.transports);

    app.provide(key, logger);

    app.config.errorHandler = (err, vm, info) => {
      logger.error("unhandled exception", err, { info }, vm?.$options.name);
      throw err;
    };
    window.onerror = function (...args: Array<unknown>): void {
      logger.error("unhandled exception", { ...args }, {}, "Window");
    };
  },
};

/**
 * Gets the currently registered logger.
 *
 * @param context - If provided, sets the logger's context. Generally, this should be the name of the component using the logger.
 * @returns - The logger to use.
 */
export function useLogger(context?: string): Logger {
  const logger = inject(key);
  if (!logger) {
    throw new Error("VueLogger has not been added to the app");
  }
  if (context) {
    return logger.setContext(context);
  }
  return logger;
}
