import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app h-screen w-screen relative overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_router_view = _resolveComponent("app-router-view")!
  const _component_idle = _resolveComponent("idle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_app_router_view)
    ]),
    (_ctx.idle > 0)
      ? (_openBlock(), _createBlock(_component_idle, {
          key: 0,
          time: _ctx.idle
        }, null, 8, ["time"]))
      : _createCommentVNode("", true)
  ], 64))
}