import { DirectiveBinding, DirectiveHook } from "vue";
import { memoize, once } from "lodash";

/**
 * For various reasons click events can be unreliable on touch screens.
 * If the user appears to move at all during the touch, it can cancel the
 * click event. Additionally, click events can take 300ms to fire after the
 * touch event occurs.
 *
 * In order to make certain elements seem more responsive, this directive adds
 * events listeners for all relevant mouse and touch events and invokes the handler
 * as soon as a "click" or "tap" is detected.
 */
export const Tap: DirectiveHook = memoize(
  (
    el: HTMLElement,
    binding: DirectiveBinding<(event: MouseEvent | TouchEvent) => void>
  ) => {
    let handler = once(binding.value);

    el.addEventListener("mousedown", () => (handler = once(binding.value)));
    el.addEventListener("touchstart", () => (handler = once(binding.value)));
    el.addEventListener("click", (ev) => handler(ev));
    el.addEventListener("mouseup", (ev) => handler(ev));
    el.addEventListener("touchend", (ev) => handler(ev));
  }
);
