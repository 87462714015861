
import { RouteNames } from "@/router";
import { defineComponent, onMounted } from "vue";
import { RouteLocationRaw } from "vue-router";
import RouterLinkButton from "@/components/RouterLinkButton.vue";
import { useStore } from "vuex";
import { key, reset } from "@/store";
import { useAppSettings } from "@/plugins/app-settings/AppSettings";
import ButterflySequence from "@/components/ButterflySequence.vue";
import { Tap } from "@/components/Tap";

export default defineComponent({
  name: "Home",
  components: {
    RouterLinkButton,
    ButterflySequence,
  },
  directives: {
    Tap,
  },
  setup() {
    const { mode } = useAppSettings();
    const store = useStore(key);

    onMounted(() => reset(store));

    return {
      nextRoute: { name: RouteNames.PickButterfly } as RouteLocationRaw,
      mode,
    };
  },
});
