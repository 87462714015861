import { inject, InjectionKey, Plugin } from "vue";

/**
 * The settings exposed throughout the app.
 */
export interface AppSettings {
  readonly [key: string]: string | number;

  /**
   * Indicates the "mode" for the application.
   *
   * "kiosk" means the app is running as a desktop application, generally with just a touchscreen for
   *   an input device.
   * "mobile" means the app is running as mobile website on a variety of devices.
   */
  readonly mode: "kiosk" | "mobile";

  /**
   * The base url used to connect to the REST api.
   */
  readonly baseApiUrl: string;

  /**
   * The base url used to connect to the WebSocket server.
   */
  readonly webSocketUrl: string;

  /**
   * The time in minutes to wait after the last user interaction
   * before resetting the app.
   */
  readonly idle: number;
}

const key: InjectionKey<AppSettings> = Symbol();

/**
 * Adds static settings to expose throughout the app.
 */
export const AppSettings: Plugin = {
  install(app, options: AppSettings): void {
    app.provide(key, options);
  },
};

/**
 * Gets the currently configured settings.
 *
 * @returns - The app settings.
 */
export function useAppSettings(): AppSettings {
  const settings = inject(key);
  if (!settings) {
    throw new Error("AppSettings has not been added to the app");
  }
  return settings;
}
