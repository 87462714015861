import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { Object3D, Vector3 } from "three";
import { Ref } from "vue";
import { Behavior } from "./Behavior";

gsap.registerPlugin(MotionPathPlugin);

/**
 * Moves an object along points on a path.
 */
export class MoveBehavior implements Behavior {
  private tween: gsap.core.Tween | null;
  private x: number;
  private y: number;
  private z: number;
  private rotation: number;

  /**
   * @constructor
   *
   * @param object - The object to update.
   * @param points - The points on the path. This is an array of tuples with the first value being the position and the second value being the rotation.
   * @param duration - The amount of time in ms that
   * @param speed - The speed of the movement. Evaluated on each update.
   */
  constructor(
    private readonly object: Object3D,
    private readonly points: Array<[Vector3, number]>,
    private readonly duration: number,
    private readonly speed: () => number
  ) {
    this.x = object.position.x;
    this.y = object.position.y;
    this.z = object.position.z;
    this.rotation = object.rotation.z;
    this.tween = null;
  }

  /**
   * Represents how far along the path the object has traveled. Will be a number between 0 and 1.
   */
  public get progress(): number {
    return this.tween ? this.tween.progress() : 0;
  }

  public update(): void {
    if (!this.tween) {
      this.tween = this.move();
    }

    this.tween.timeScale(Math.max(this.speed(), 0.25));

    this.object.position.set(this.x, this.y, this.z);
    this.object.rotation.z = this.rotation;
  }

  private move(): gsap.core.Tween {
    return gsap.to(this, {
      motionPath: {
        path: this.points.map(([position, rotation]) => ({
          x: position.x,
          y: position.y,
          z: position.z,
          rotation: rotation,
        })),
        curviness: 0.5,
        fromCurrent: true,
      },
      duration: this.duration / 1000,
      ease: "sine.inOut",
    });
  }
}
