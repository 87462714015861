import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component, route }) => [
      _createVNode(_Transition, { name: _ctx.transition }, {
        default: _withCtx(() => [
          (_ctx.keepAlive)
            ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.fullPath,
                  class: "absolute inset-0 h-screen w-screen overflow-auto"
                }))
              ], 1024))
            : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: 1,
                class: "absolute inset-0 h-screen w-screen overflow-auto"
              }))
        ]),
        _: 2
      }, 1032, ["name"])
    ]),
    _: 1
  }))
}