
import { defineComponent } from "vue";

/**
 * The Alert component shows a message on top of all other content at
 * the bottom of the screen.
 */
export default defineComponent({
  name: "Alert",
  props: {
    /**
     * Indicates if the alert should be shown.
     */
    show: {
      type: Boolean,
      required: true,
    },
  },
});
