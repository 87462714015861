
import { defineComponent } from "vue";

/**
 * Button that links to a route within the app.
 */
export default defineComponent({
  name: "RouterLinkButton",
  props: {
    /**
     * Indicates if the button is disabled, meaning the user cannot
     * navigate to the defined route.
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
