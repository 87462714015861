/**
 * Represents the Design Types the user can select from when building a butterfly.
 */
export enum ButterflyDesignType {
  Simple = "simple",
  Intermediate = "intermediate",
  Complex = "complex",
}

/**
 * Get a label to show with the DesignType.
 *
 * @param type - The DesignType.
 * @returns - A user friendly label for the design type.
 */
export function getDesignTypeLabel(type: ButterflyDesignType): string {
  const labels = {
    [ButterflyDesignType.Simple]: "Simple Butterfly",
    [ButterflyDesignType.Intermediate]: "Intermediate Butterfly",
    [ButterflyDesignType.Complex]: "Complex Butterfly",
  };

  return labels[type] || "Butterfly";
}

/**
 * Get the url the svg file associated with the provided DesignType.
 *
 * @param type - The DesignType.
 * @returns - The svg file for the provided design type.
 */
export function getDesignTypeSvgSrc(type: ButterflyDesignType): string {
  switch (type) {
    case ButterflyDesignType.Simple:
      return require("@/assets/images/butterflies/simple.svg");
    case ButterflyDesignType.Intermediate:
      return require("@/assets/images/butterflies/intermediate.svg");
    case ButterflyDesignType.Complex:
      return require("@/assets/images/butterflies/complex.svg");
    default:
      throw new Error(`Unknown design type "${type}"`);
  }
}
