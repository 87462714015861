
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";

/**
 *
 */
export default defineComponent({
  name: "ButterflyAnimation",
  setup() {
    const fpsInterval = 1000 / 30;

    const context = require.context(
      "@/assets/images/butterfly-sequence",
      false,
      /.*/
    );
    const frames = context.keys().map(context);

    const elapsedTime = ref<number>(0);
    const handle = ref<ReturnType<typeof requestAnimationFrame> | null>(null);
    const frameIndex = ref(0);
    const nextFrame = (time: number, previousTime: number): void => {
      handle.value = requestAnimationFrame((nextTime) =>
        nextFrame(nextTime, time)
      );

      elapsedTime.value += time - previousTime;
      if (elapsedTime.value > fpsInterval) {
        elapsedTime.value %= fpsInterval;
        frameIndex.value = (frameIndex.value + 1) % frames.length;
      }
    };

    onMounted(() => nextFrame(0, 0));
    onUnmounted(() => {
      if (handle.value) {
        cancelAnimationFrame(handle.value);
      }
    });

    return {
      frames,
      frameIndex,
    };
  },
});
