import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af5892e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "butterfly-sequence relative" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frames, (frame, index) => {
      return (_openBlock(), _createElementBlock("img", {
        key: frame,
        src: frame,
        class: _normalizeClass(["absolute h-full w-full", index === _ctx.frameIndex ? 'opacity-1' : 'opacity-0'])
      }, null, 10, _hoisted_2))
    }), 128))
  ]))
}