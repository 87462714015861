
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import Keyboard from "simple-keyboard";
import { useLogger } from "@/plugins/logger/VueLogger";

/**
 * Shows a virtual keyboard on the screen.
 */
export default defineComponent({
  name: "ScreenKeyboard",
  props: {
    /**
     * The current value.
     */
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: [
    /**
     * Emitted when the user changes modelValue.
     */
    "update:modelValue",
  ],
  setup(props, { emit }) {
    const log = useLogger("ScreenKeyboard");
    const keyboardElement = ref<HTMLDivElement | null>(null);
    const keyboard = ref<Keyboard | null>(null);
    onMounted(() => {
      if (!keyboardElement.value) {
        log.warn("keyboard element not found");
        return;
      }

      keyboard.value = buildKeyboard(
        keyboardElement.value,
        props.modelValue,
        (input) => emit("update:modelValue", input)
      );
    });
    watch(
      () => props.modelValue,
      (modelValue) => {
        if (!keyboard.value) return;

        keyboard.value.setInput(modelValue);
      }
    );
    onUnmounted(() => {
      if (!keyboard.value) return;

      keyboard.value.destroy();
      keyboard.value = null;
    });

    return {
      keyboard: keyboardElement,
    };
  },
});

function buildKeyboard(
  element: HTMLDivElement,
  initialInput: string,
  onChange: (input: string) => void
): Keyboard {
  const keyboard = new Keyboard(element, {
    layout: {
      default: [
        "Q W E R T Y U I O P {bksp}",
        "A S D F G H J K L",
        "Z X C V B N M ; : - /",
        "{alt} {space} {alt}",
      ],
      alt: [
        "1 2 3 4 5 6 7 8 9 0 {bksp}",
        "@ # $ & * ( ) ' \"",
        "% - + = / ; : < > ! ?",
        "{default} {space} {default}",
      ],
    },
    display: {
      "{default}": "ABC",
      "{bksp}": "⌫",
      "{space}": " ",
      "{alt}": ".?123",
    },
    layoutName: "default",
    theme: "di-keyboard",
    onChange: onChange,
    onKeyPress: (button: string) => {
      switch (button) {
        case "{alt}":
          keyboard.setOptions({ layoutName: "alt" });
          break;
        case "{default}":
          keyboard.setOptions({ layoutName: "default" });
          break;
      }
    },
  });
  keyboard.setInput(initialInput);
  return keyboard;
}
