
import { RouteNames } from "@/router";
import {
  getButterflyDataUrl,
  getButterflyName,
  key,
  setButterflyName,
} from "@/store";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import BackButton from "@/components/BackButton.vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import Alert from "@/components/Alert.vue";
import ScreenKeyboard from "@/components/ScreenKeyboard.vue";
import { useLogger } from "@/plugins/logger/VueLogger";
import { useAppSettings } from "@/plugins/app-settings/AppSettings";
import Filter from "bad-words";
import * as BadWords from "@dimensional-innovations/bad-words-collection";
import RouterLinkButton from "@/components/RouterLinkButton.vue";
import { Tap } from "@/components/Tap";

export default defineComponent({
  name: "NameButterfly",
  components: {
    RouterLinkButton,
    BackButton,
    ScreenKeyboard,
    Alert,
  },
  directives: {
    Tap,
  },
  setup() {
    const log = useLogger("NameButterfly");
    const router = useRouter();
    const store = useStore(key);
    const { mode } = useAppSettings();

    onMounted(() => {
      const dataUrl = getButterflyDataUrl(store);
      if (!dataUrl) {
        log.warn("DataUrl not set");
        router.replace({ name: RouteNames.Home });
      }
    });

    const butterflyName = computed({
      get(): string {
        return getButterflyName(store) || "";
      },
      set(value: string) {
        log.trace("butterflyName.set", { value });
        setButterflyName(store, value);
      },
    });
    const showAlert = ref(false);
    const filter = new Filter({ list: BadWords.en.concat(BadWords.es) });
    const cleanButterflyName = () => {
      if (!filter.isProfane(butterflyName.value)) return false;

      butterflyName.value = filter.clean(butterflyName.value);
      showAlert.value = true;

      // Watch for the next change to butterflyName to reset the value and dismiss the alert.
      const unwatch = watch(butterflyName, (newValue, oldValue) => {
        butterflyName.value = newValue.substring(oldValue.length);
        showAlert.value = false;
        unwatch();
      });
      return true;
    };
    watch(butterflyName, (value) => {
      if (value.endsWith(" ")) {
        cleanButterflyName();
      }
    });

    return {
      prevRoute: { name: RouteNames.ColorButterfly } as RouteLocationRaw,
      nextRoute: { name: RouteNames.ReleaseButterfly } as RouteLocationRaw,
      canGoNext: computed(
        () => !!butterflyName.value.trim() && !showAlert.value
      ),

      butterflySrc: computed(() => getButterflyDataUrl(store)),

      showAlert: showAlert,
      showKeyboard: mode === "kiosk",

      butterflyName: butterflyName,
      onSubmit: ($event: Event): void => {
        log.trace("onSubmit", { butterflyName: butterflyName.value });

        $event.preventDefault();
        if (!cleanButterflyName()) {
          router.push({ name: RouteNames.ReleaseButterfly });
        }
      },
      onClickNext: (): void => {
        log.trace("onClickNext", { butterflyName: butterflyName.value });

        if (!cleanButterflyName()) {
          router.push({ name: RouteNames.ReleaseButterfly });
        }
      },
    };
  },
});
